import React from 'react'
import './../App.css';

const HeroSrction = () => {


    const handleServices = (e) => {
        e.preventDefault()
        window.open('https://book.appt.cm/#/business/3f5ba17a-6f3a-4d2d-bf03-4a99cc773e83/embed/view/task?page=task', '_blank');
    }

    return (
        <div className='hero-main'>
            <img
                loading="lazy"
                srcSet="./assets/images/herogirl.png"
                alt=''
                className="hero-image hidden-img"
            />
            <div className="flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                <div className="flex flex-col items-stretch w-[55%] sm:w-[100%] max-md:w-full max-md:ml-0">
                    <div
                        className="bg-red-100 bg-[url('./assets/images/flower-icon.png')] bg-no-repeat  flex grow flex-col justify-center w-full px-10 lg:py-12 md:py-12 items-start max-md:max-w-full max-md:px-5">
                        <div className="flex w-[607px] max-w-full flex-col px-10 mt-28 mb-10 items-start max-md:mt-10">
                            <div className="text-[#121212] text-left text-5xl font-bold leading-[60px] self-stretch max-md:max-w-full max-md:text-4xl max-md:leading-[56px]">
                                Bring back the beauty and glow of your skin
                            </div>
                            <div className="text-[#797979] text-left text-lg font-medium leading-8 self-stretch mt-9 max-md:max-w-full">
                                We provide you various treatments from head to toe using the
                                best product, advanced technology, and affordable price
                            </div>
                            <button onClick={(e) => handleServices(e)} className="text-[#121212] text-base font-medium uppercase whitespace-nowrap justify-center items-stretch rounded bg-white hover:bg-[#121212] hover:text-[#ffffff] mt-8 px-5 py-4 duration-200">
                                Book Appointment
                            </button>
                        </div>
                    </div>
                </div>
                <div className=" bg-[#FFFFFF] flex-col bg-[url('./assets/images/flower-icon.png')] bg-no-repeat items-stretch w-[45%] hidden lg:block max-md:w-full max-md:ml-0">
                    <div className="bg-transparent flex grow flex-col w-full lg:pl-8 md:pl-5 lg:pr-2 py-20 items-end max-md:max-w-full max-md:px-5">
                        <div
                            style={{
                                background:
                                    "linear-gradient(180deg, #353B4A 0%, rgba(53, 59, 74, 0.39) 109.3%)",
                            }}
                            className="backdrop-blur-[6px] bg-gradient-to-t from-[#353B4A] from-100% to-[#353B4A] to-39% flex w-[400px] max-w-full flex-col p-3 rounded-xl items-start">
                            <div className="text-[#FFFFFF] text-left text-base leading-5 self-stretch mt-3">
                                “Akshi has been doing my brows for nearly a year now. If you’ve experienced eyebrow threading, you know how crucial it is to find a skilled artist! Akshi is not only professional and kind but also a true threading artist. I often bring my friends to her, and they always leave feeling amazing. She excels at understanding how to shape brows and listens to your preferences to ensure 100% satisfaction. I trust her completely! And all thanks to Akshi. ❤️”
                            </div>
                            <div className="text-[#FFFFFF] text-left text-base font-semibold self-stretch whitespace-nowrap mt-3">
                                - Brittany Agustin
                            </div>
                        </div>
                        <div
                            style={{
                                background:
                                    "linear-gradient(180deg, #353B4A 0%, rgba(53, 59, 74, 0.39) 109.3%)",
                            }}
                            className="backdrop-blur-[6px] bg-gradient-to-b from-[#353B4A] from-10% to-[#353B4A] self-stretch w-[400px] flex flex-col mt-9 pl-5 pr-6 py-5 rounded-xl items-start max-md:pr-5">
                            <div className="text-[#FFFFFF] text-base text-left leading-5 self-stretch mt-3">
                                “First of all, I drive 45 minutes and pass by other salons to get threaded by Amita, which should speak for itself – she is amazing! She’s highly knowledgeable and skilled at making me look my best. Amita is also very patient, especially since I’m not always the easiest client. Her salon is both lovely and inviting. I would wholeheartedly recommend it to anyone and everyone!”
                            </div>{" "}
                            <div className="text-[#FFFFFF] text-left text-base font-semibold self-stretch whitespace-nowrap mt-3">
                                -Kelly K
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeroSrction