import React from 'react'
import ServiceComponent from './ServiceComponent';

const threadingData = {
    title: 'Eyelashes',
    description:
        "Elevate your natural beauty with our exquisite facial tinting. Achieve enhanced definition and a radiant glow as our expert touch brings out the best in your features. Embrace a look that's both subtle and striking, ensuring lasting elegance that effortlessly complements your unique charm.",
    benefits: [
        'Enhanced Definition: Experience the artistry of enhanced facial features, as our tinting technique brings forth a subtle yet striking definition.',
        'Natural Radiance:  Revel in the natural radiance that our specialized tinting imparts to your facial aesthetics, creating a harmonious and effortless glow.',
        'Lasting Elegance:  Immerse yourself in the enduring elegance of tinted features, ensuring a timeless allure that transcends the ordinary.',
        'Looks attractive and enhance your eyes lashes.',
        'Weightless and gives natural, beautiful looks'
    ],
    imageSrc: './assets/images/tinting.png',
    benefitTitle: 'Eyelashes'
};




const Eyelashes = () => {
    return (
        <ServiceComponent {...threadingData} />
    )
}

export default Eyelashes