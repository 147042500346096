import * as React from "react";
import { useNavigate } from 'react-router-dom';

const BeautyServices = () => {
  const navigate = useNavigate();

  const onServices = (path) => {
    navigate(`/${path}`);
  }

  return (
    <div className="flex flex-col items-center py-10">
      <div className="flex w-full max-w-[1320px] flex-col items-stretch px-5 max-md:max-w-full">
        <div className="text-[#353B4A] text-left text-4xl font-semibold leading-8 w-[318px] max-w-full self-start">
          Our Services
        </div>
        <div className="text-[#696969] w-full lg:w-[400px] text-left text-md leading-5 mt-4">
          Discover the essence of beauty with our expert services – where your
          radiant transformation begins.
        </div>
        <div className="mt-5 max-md:max-w-full">
          <div className="lg:gap-5 md:gap-0 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
            <div className="flex flex-col items-stretch w-[27%] max-md:w-full max-md:ml-0">
              <div className="justify-between items-stretch self-stretch bg-white flex grow flex-col w-full max-md:mt-10">
                <button onClick={() => onServices('threading')} className="text-white text-lg font-medium leading-5 items-stretch bg-[#353B4A] justify-center px-2 py-3 rounded-md">
                  Threading
                </button>
                <button onClick={() => onServices('waxing')} className="text-neutral-900 text-lg hover:bg-[#353B4A] hover:text-white  font-medium leading-5 items-stretch border justify-center mt-3 px-2 py-3 rounded-md border-solid border-[#D3D3D3]-600 duration-200">
                  Waxing
                </button>
                <button onClick={() => onServices('facial')} className="text-neutral-900 text-lg hover:bg-[#353B4A] hover:text-white font-medium leading-5 items-stretch border justify-center mt-3 px-2 py-3 rounded-md border-solid border-[#D3D3D3]-600 duration-200">
                  Facial
                </button>
                <button onClick={() => onServices('henna')} className="text-neutral-900 text-lg hover:bg-[#353B4A] hover:text-white font-medium leading-5 items-stretch border justify-center mt-3 px-2 py-3 rounded-md border-solid border-[#D3D3D3]-600 duration-200">
                  Henna
                </button>
                <button onClick={() => onServices('make-up')} className="text-neutral-900 text-lg hover:bg-[#353B4A] hover:text-white font-medium leading-5 items-stretch border justify-center mt-3 px-2 py-3 rounded-md border-solid border-[#D3D3D3]-600 duration-200">
                  Make-Up
                </button>
                <button onClick={() => onServices('hairstyle')} className="text-neutral-900 text-lg hover:bg-[#353B4A] hover:text-white font-medium leading-5 items-stretch border justify-center mt-3 px-2 py-3 rounded-md border-solid border-[#D3D3D3]-600 duration-200">
                  Hairstyle
                </button>
                <button onClick={() => onServices('eyelashes')} className="text-neutral-900 text-lg hover:bg-[#353B4A] hover:text-white font-medium leading-5 items-stretch border justify-center mt-3 px-2 py-3 rounded-md border-solid border-[#D3D3D3]-600 duration-200">
                  Eyelashes
                </button>
              </div>
            </div>


            <div className="hidden lg:block md:block flex flex-col items-stretch w-[73%] ml-5 max-md:w-full max-md:ml-0">
              <div className="grow h-[100%] max-md:max-w-full max-md:mt-10">
                <div className="gap-5 flex h-[100%] max-md:flex-col w-full max-md:items-stretch max-md:gap-0">
                  <div className="flex flex-col items-stretch h-[100%] w-[28%] max-md:w-full max-md:ml-0">
                    <img
                      loading="lazy"
                      srcSet="./assets/images/threading.png"
                      alt=""
                      className="aspect-[0.67] h-[100%] object-contain object-center w-full self-stretch overflow-hidden grow max-md:mt-2"
                    />
                  </div>
                  <div className="flex flex-col items-stretch w-[28%] ml-5 max-md:w-full max-md:ml-0">
                    <img
                      loading="lazy"
                      srcSet="./assets/images/waxing-img.png"
                      alt=""
                      className="aspect-[0.67] object-contain object-center h-full w-full overflow-hidden grow max-md:mt-2"
                    />
                  </div>
                  <div className="flex flex-col items-stretch w-[28%] ml-5 max-md:w-full max-md:ml-0">
                    <img
                      loading="lazy"
                      srcSet="./assets/images/facial-girl.png"
                      alt=""
                      className="aspect-[0.67] object-contain object-center w-full overflow-hidden grow max-md:mt-2"
                    />
                  </div> 
                  <div className="flex flex-col items-stretch w-[28%] ml-5 max-md:w-full max-md:ml-0">
                    <img
                      loading="lazy"
                      srcSet="./assets/images/mahendi.png"
                      alt=""
                      className="aspect-[0.67] object-contain object-center w-full overflow-hidden grow max-md:mt-2"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BeautyServices