import React from 'react'
import ServiceComponent from './ServiceComponent';

const threadingData = {
    title: 'Make-Up',
    description:
        "Step into the world of makeup and redefine your beauty with a touch of artistry. From boosting confidence to expressing creativity, makeup is your daily dose of empowerment. Effortlessly conceal imperfections, adapt your look to any occasion, and embrace the versatile glamour that enhances your unique features. Makeup is more than a routine; it's your personal statement of elegance, leaving a lasting impression with every stroke.",
    benefits: [
        'Refined Texture: Our precise technique enhances the texture of your facial skin, leaving it velvety smooth.',
        'Subtle Transformation: Witness a gradual reduction in unwanted elements, revealing a refreshed and polished facial appearance.',
        'Long-lasting Elegance: Enjoy the enduring effects of our treatment, ensuring a sustained and timeless radiance.',
    ],
    imageSrc: './assets/images/makeup.png',
    benefitTitle: 'Make-Up'
};


const MakeUp = () => {
    return (
        <ServiceComponent {...threadingData} />
    )
}

export default MakeUp