import React, { useState, useRef } from 'react';
// import axios from 'axios';
// import $ from 'jquery';
import { Card } from 'flowbite-react';
import emailjs from 'emailjs-com';


import './../App.css';

const ContactForm = () => {
  const formRef = useRef(null);
  const [dataObj, setDataObj] = useState({ name: '', email: '', phone: '', services: '', notes: '' })

  const handleChange = (e) => {
    setDataObj({ ...dataObj, [e.target.name]: e.target.value })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    const templateParams = {
      from_name: dataObj.name,
      from_email: dataObj.email,
      from_phone: dataObj.phone,
      service_request: dataObj.services,
      message: dataObj.notes,
    };

    emailjs.send('service_5wwnoc7', 'template_ykziuue', templateParams, 'T6Apni6ww_j-X4d6r')
      .then((result) => {
        console.log(result.text);
        setDataObj({ name: '', email: '', phone: '', services: '', notes: '' })
        // Display success message or perform further actions
      }, (error) => {
        console.log(error.text);
        // Handle errors here
      });

    // $(".loading").show();
    // console.log(dataObj);

    // $.ajax({
    //   url: './php/contact.php',
    //   dataType: 'json',
    //   type: 'POST',
    //   async: true,
    //   data: dataObj,
    //   success: function (data) {
    //     $(".loading").hide();
    //     if (data === 'success') {
    //       $(".sent-message").show();
    //       setTimeout(() => {
    //         $(".sent-message").hide();
    //       }, 3000);
    //     } else {
    //       $('.error-message').text('Something Went Wrong. Please Try Again Later');
    //       $(".error-message").show();
    //       setTimeout(() => {
    //         $(".error-message").hide();
    //       }, 3000);
    //     }
    //   },
    //   error: function (xhr, exception) {
    //     $(".loading").hide();
    //     $('.error-message').text('Something Went Wrong. Please Try Again Later');
    //     $(".error-message").show();
    //     setTimeout(() => {
    //       $(".error-message").hide();
    //     }, 3000);
    //   },
    // });

    // const formDataObj = new FormData();
    // formDataObj.append('fname', dataObj.name)
    // formDataObj.append('email', dataObj.email)
    // formDataObj.append('phone', dataObj.phone)
    // formDataObj.append('message', dataObj.notes)

    // try {
    //   const response = await axios.post('/contact.php', formDataObj);
    //   setDataObj({ name: '', email: '', phone: '', services: '', notes: '' })
    //   console.log(response.data);
    // } catch (error) {
    //   console.error('Error sending email:', error);
    // }
  }

  return (
    <div className="mx-auto mb-5 max-w-7xl sm:px-6 lg:px-8">
      <form ref={formRef} onSubmit={(e) => handleSubmit(e)}>
        <div className="flex flex-col items-stretch">
          <div className="flex-col overflow-hidden relative flex min-h-[580px] lg:w-full justify-center mt-10 px-16 items-end max-md:max-w-full max-md:mt-10 max-md:px-5">
            <img
              loading="lazy"
              srcSet='./assets/images/contact.png'
              className="absolute top-10 w-full object-cover object-center inset-0 h-[90%]"
              // height='700px'
              alt=''
            />
            <div className="relative justify-center items-center rounded z-[1] flex mt-0 max-w-full flex-col mr-11 md:mr-0 max-md:mr-0 max-md:px-5">
              <Card className='card'>

                <div className="text-neutral-900 text-center lg:text-3xl md:text-2xl sm:text-4xl font-semibold leading-10 self-stretch whitespace-nowrap max-md:max-w-full">
                  Contact us for any Questions
                </div>
                <div className="border bg-white self-center flex w-[400px] max-w-full flex-col justify-center mt-5 pl-4 pr-16 py-5 rounded-md border-solid border-zinc-400 items-start max-md:pr-5">
                  <div className="items-stretch flex gap-3">
                    <img
                      loading="lazy"
                      srcSet='./assets/images/person.svg'
                      className="aspect-square object-contain object-center w-6 overflow-hidden shrink-0 max-w-full"
                      alt="Name"
                    />
                    <input
                      type="text"
                      id="large-input"
                      className='input-field'
                      name='name'
                      value={dataObj.name}
                      onChange={handleChange}
                      placeholder='Name'
                      aria-label="Name"
                      required
                    />
                  </div>
                </div>
                <div className="border bg-white self-center flex w-[400px] max-w-full flex-col justify-center  pl-4 pr-16 py-5 rounded-md border-solid border-zinc-400 items-start max-md:pr-5">
                  <div className="items-stretch flex gap-3">
                    <img
                      loading="lazy"
                      srcSet='./assets/images/email.svg'
                      className="aspect-square object-contain object-center w-6 overflow-hidden shrink-0 max-w-full"
                      alt="Email"
                    />
                    <input
                      type="email"
                      className='input-field'
                      placeholder='Email'
                      id="email"
                      name='email'
                      value={dataObj.email}
                      onChange={handleChange}
                      aria-label="Email"
                      required
                    />
                  </div>
                </div>
                <div className="border bg-white self-center flex w-[400px] max-w-full flex-col justify-center  pl-4 pr-16 py-5 rounded-md border-solid border-zinc-400 items-start max-md:pr-5">
                  <div className="items-stretch flex gap-3">
                    <img
                      loading="lazy"
                      srcSet='./assets/images/phone.svg'
                      className="aspect-square object-contain object-center w-6 overflow-hidden shrink-0 max-w-full"
                      alt="Phone"
                    />
                    <input
                      type="tel"
                      className='input-field'
                      placeholder='Phone'
                      id="phone"
                      name='phone'
                      value={dataObj.phone}
                      onChange={handleChange}
                      aria-label="Phone"
                      required
                    />
                  </div>
                </div>
                <div className="border bg-white self-center flex w-[400px] max-w-full flex-col justify-center pl-4 pr-16 py-5 rounded-md border-solid border-zinc-400 items-start max-md:pr-5">
                  <div className="items-stretch flex gap-3">
                    <img
                      loading="lazy"
                      srcSet='./assets/images/book-open.svg'
                      className="aspect-square object-contain object-center w-6 overflow-hidden shrink-0 max-w-full"
                      alt="Service You Need"
                    />
                    <input
                      type="text"
                      className='input-field'
                      placeholder='Service You Need'
                      name='services'
                      value={dataObj.services}
                      onChange={handleChange}
                      id="service"
                      aria-label="Service You Need"
                      required
                    />
                  </div>
                </div>
                <div className="border bg-white self-center flex w-[400px] max-w-full flex-col pl-5 pr-16 pt-5 pb-12 rounded-md border-solid border-zinc-400 items-start max-md:pr-5">
                  <div className="items-stretch flex mb-11 max-md:mb-10">
                    <img
                      loading="lazy"
                      srcSet='./assets/images/edit.svg'
                      className="aspect-square object-contain object-center w-[23px overflow-hidden shrink-0 max-w-full"
                      alt="Any Note For Us"
                    />
                    <textarea
                      name='notes'
                      className="custom-textarea"
                      value={dataObj.notes}
                      onChange={handleChange}
                      placeholder='Any Note For Us'
                      required
                    />
                  </div>
                </div>
                <button
                  type='submit'
                  style={{ backgroundColor: '#353B4A' }}
                  className="text-white text-center text-base font-semibold tracking-wider uppercase whitespace-nowrap justify-center items-stretch rounded bg-blue-800 self-center mt-4 px-12 py-4 max-md:px-5"
                >
                  ask a question
                </button>
              </Card>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default ContactForm