import Header from '../components/Header'
import Footer from '../components/Footer'
import { Outlet } from 'react-router-dom'

export default function Layout() {
    return (
        <>
            <div className="min-h-full w-full">
                <Header />
                <main>
                    <div>
                        <Outlet />
                    </div>
                </main>
                <Footer />
            </div>
        </>
    )
}
