import React from 'react'
import { useNavigate } from 'react-router-dom';

const Henna = () => {

    const navigate = useNavigate();
    const handleServices = (e) => {
        e.preventDefault()
        navigate('/contact-us');
    }
    return (
        <div className="flex flex-col items-stretch mb-10">
            <div className="bg-indigo-200 flex w-full flex-col justify-center items-stretch max-md:max-w-full">
                <div className="flex-col text-neutral-900 text-5xl font-semibold tracking-widest relative whitespace-nowrap overflow-hidden min-h-[229px] w-full justify-center items-center px-16 py-20 max-md:text-4xl max-md:max-w-full max-md:px-5">
                    <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/d3a33f2663f1f2d6b140cfa58a8e258322a449e9b872029ebe1ab80e895d4b50?apiKey=fc85fc7c7f024bdfa1247fe0addfd173&"
                        alt=''
                        className="absolute h-full w-full object-cover object-center inset-0"
                    />
                    Henna
                </div>
            </div>
            <div className="self-center w-full max-w-[1200px] mt-20 max-md:max-w-full max-md:mt-10">
                <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                    <div className="flex flex-col items-stretch w-[43%] max-md:w-full max-md:ml-0">
                        <img
                            loading="lazy"
                            src="./assets/images/henna1.png"
                            alt=''
                            className="aspect-square object-contain object-center w-full overflow-hidden max-md:max-w-full max-md:mt-10"
                        />
                    </div>
                    <div className="flex flex-col items-stretch w-[57%] ml-5 max-md:w-full max-md:ml-0">
                        <div className="items-start flex flex-col my-auto px-5 max-md:max-w-full max-md:mt-10">
                            <div className="text-neutral-900 text-left text-base font-semibold leading-8 tracking-wider self-stretch max-md:max-w-full">
                                Temporary hair dye with the natural herb. Gives natural color to
                                your hair.
                            </div>
                            <div className="text-neutral-900 text-left text-2xl font-extrabold tracking-widest capitalize self-stretch mt-4 max-md:max-w-full">
                                Benefits of Henna
                            </div>
                            <div className='pl-5'>
                                <div className="text-neutral-900 text-left text-base font-bold tracking-wider capitalize self-stretch mt-4 max-md:max-w-full">
                                    <ul className='list-disc'>
                                        <li>Natural color dye.</li>
                                    </ul>
                                </div>
                                <div className="text-neutral-900 text-left text-base font-bold tracking-wider capitalize self-stretch mt-3 max-md:max-w-full">
                                    <ul className='list-disc'>
                                        <li>No chemicals used</li>
                                    </ul>
                                </div>
                                <div className="text-neutral-900 text-left text-base font-bold tracking-wider capitalize self-stretch mt-3 max-md:max-w-full">
                                    <ul className='list-disc'>
                                        <li>Keeps head relax and cool.</li>
                                    </ul>
                                </div>
                            </div>
                            <button onClick={(e) => handleServices(e)} className="text-white text-base font-medium uppercase whitespace-nowrap justify-center items-stretch rounded bg-[#353B4A] mt-9 px-12 py-4 self-start max-md:px-5">
                                Book Appointment
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="self-center w-full max-w-[1200px] mt-12 max-md:max-w-full max-md:mt-10">
                <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                    <div className="flex flex-col items-stretch w-[57%] max-md:w-full max-md:ml-0">
                        <div className="items-start flex flex-col my-auto px-5 max-md:max-w-full max-md:mt-10">
                            <div className="text-neutral-900 text-left text-base font-semibold leading-8 tracking-wider self-stretch max-md:max-w-full">
                                Henna Tattoo is a natural dye prepared from an herb called
                                “Lawsonia Inermis” (Hina).Benefits of Henna Tattoo.
                            </div>
                            <div className="text-neutral-900 text-left text-2xl font-extrabold tracking-widest capitalize self-stretch mt-4 max-md:max-w-full">
                                Temporary design of your style
                            </div>
                            <div className='pl-5'>
                                <div className="text-neutral-900 text-left text-base font-bold tracking-wider capitalize self-stretch mt-4 max-md:max-w-full">
                                    <ul className='list-disc'>
                                        <li>Last for 7-10 days.</li>
                                    </ul>
                                </div>
                                <div className="text-neutral-900 text-left text-base font-bold tracking-wider capitalize self-stretch mt-3 max-md:max-w-full">
                                    <ul className='list-disc'>
                                        <li>Painless application</li>
                                    </ul>
                                </div>
                                <div className="text-neutral-900 text-left text-base font-bold tracking-wider capitalize self-stretch mt-3 max-md:max-w-full">
                                    <ul className='list-disc'>
                                        <li>Chemical free.</li>
                                    </ul>
                                </div>
                            </div>
                            <button onClick={(e) => handleServices(e)} className="text-white text-base font-medium uppercase whitespace-nowrap justify-center items-stretch rounded bg-[#353B4A] mt-9 px-12 py-4 self-start max-md:px-5">
                                Book Appointment
                            </button>
                        </div>
                    </div>
                    <div className="flex flex-col items-stretch w-[43%] ml-5 max-md:w-full max-md:ml-0">
                        <img
                            loading="lazy"
                            src="./assets/images/henna2.png"
                            alt=''
                            className="aspect-square object-contain object-center w-full overflow-hidden max-md:max-w-full max-md:mt-10"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Henna