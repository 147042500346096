import React from 'react'
import ServiceComponent from './ServiceComponent';

const threadingData = {
    title: 'Waxing',
    description:
        "The most common way of achieving a smooth, flawless hair free skin by waxing for both men and women. Overtime, hair growth may be lessened. Waxing involves application of thin layer of wax and pulling off with strips. Waxing prevents ingrowth hairs.",
    benefits: [
        'Frequent waxing makes hair grow finer and slow.',
        'Gives smooth and flawless skin with no cuts, scar.',
        'Waxing last longer.',
    ],
    imageSrc: './assets/images/wax-service.png',
    benefitTitle: 'Waxing'
};

const Waxing = () => {
    return (
        <ServiceComponent {...threadingData} />
    )
}

export default Waxing