import './App.css';
import Homepage from './components/Homepage';
import Layout from './pages/Layout';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Threading from './components/Threading'
import Waxing from './components/Waxing'
import Facial from './components/Facial'
import Henna from './components/Henna'
import MakeUp from './components/MakeUp'
import AboutMain from './components/AboutMain';
import ContactUs from './components/ContactUs';
import Eyelashes from './components/Eyelashes';
import HairStyle from './components/HairStyle';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="/" element={<Homepage />} />
            <Route path="/threading" element={<Threading />} />
            <Route path="/waxing" element={<Waxing />} />
            <Route path="/facial" element={<Facial />} />
            <Route path="/eyelashes" element={<Eyelashes />} />
            <Route path="/henna" element={<Henna />} />
            <Route path="/make-up" element={<MakeUp />} />
            <Route path="/hairstyle" element={<HairStyle />} />
            <Route path="/about-us" element={<AboutMain />} />
            <Route path="/contact-us" element={<ContactUs />} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
