import React from 'react'
import BeautyServices from '../components/BeautyServices'
import PricingSection from '../components/PricingSection'
import ContactForm from '../components/ContactForm'
import Location from '../components/Location'
import HeroSrction from '../components/HeroSrction'

const Homepage = () => {
    return (
        <div>
            <HeroSrction />
            <BeautyServices />
            <PricingSection />
            <Location />
            <ContactForm />
        </div>
    )
}

export default Homepage