import React from 'react'

const AboutMain = () => {
    return (
        <div className="flex flex-col items-center mb-10">
            <div className="bg-red-100 self-stretch flex w-full flex-col justify-center items-stretch max-md:max-w-full">
                <div className="flex-col text-neutral-900 text-5xl font-semibold tracking-widest relative whitespace-nowrap overflow-hidden min-h-[229px] w-full justify-center items-center px-16 py-20 max-md:text-4xl max-md:max-w-full max-md:px-5">
                    <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/28b59f634e417580eb8973c69d788eddab811c68bba99ecbdcff763a6fdf93cc?apiKey=fc85fc7c7f024bdfa1247fe0addfd173&"
                        className="absolute h-full w-full object-cover object-center inset-0"
                        alt=''
                    />
                    About Us
                </div>
            </div>
            <div className="w-full max-w-[1200px] mt-20 max-md:max-w-full max-md:mt-10">
                <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                    <div className="flex flex-col items-stretch w-[43%] max-md:w-full max-md:ml-0">
                        <img
                            loading="lazy"
                            src='./assets/images/aboutpage.png'
                            className="aspect-square object-contain object-center w-full overflow-hidden max-md:max-w-full max-md:mt-10"
                            alt=''
                        />
                    </div>
                    <div className="flex flex-col items-stretch w-[57%] ml-5 max-md:w-full max-md:ml-0">
                        <div className="items-stretch flex flex-col my-auto px-5 max-md:max-w-full max-md:mt-10">
                            <div className="text-neutral-900 text-2xl text-left font-bold tracking-widest capitalize max-md:max-w-full">
                                Dear Clients,
                            </div>
                            <div className="text-neutral-900 text-base text-left font-semibold tracking-wider mt-3 max-md:max-w-full">
                                Amita is a New York state licensed Esthetician and has 8 years of experience. she previously worked at Just Threading, Latham and Seva Beauty Salon, NJ. Amita's commitment to excellence shines through her dedication to providing clients with top-tier beauty services, always ensuring their satisfaction is paramount. With meticulous attention to sanitation practices, she upholds the highest standards, promising not just beauty treatments, but a safe and rejuvenating experience for all her clients.
                            </div>
                            <div className="text-neutral-900 text-left text-base font-bold tracking-wider capitalize mt-3 max-md:max-w-full">
                                WALK-IN WELCOME Recommend Appointment for Body Waxing and Makeup
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutMain