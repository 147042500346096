import React from 'react'

const ServiceComponent = ({ title, description, benefits, imageSrc, benefitTitle }) => {
    const handleServices = (e) => {
        e.preventDefault()
        window.open('https://book.appt.cm/#/business/3f5ba17a-6f3a-4d2d-bf03-4a99cc773e83/embed/view/task?page=task', '_blank');
    }

    return (
        <div className="flex flex-col items-stretch mb-10">
            <div className="bg-red-100 flex w-full flex-col justify-center items-stretch max-md:max-w-full">
                <div className="flex-col rounded-2xl text-neutral-900 text-5xl font-semibold tracking-widest relative whitespace-nowrap overflow-hidden min-h-[229px] w-full justify-center items-center px-16 py-20 max-md:text-4xl max-md:max-w-full max-md:px-5">
                    <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/c2dc2490e10f87078f8d9c2f0e82d9ae77a3b6b9f08891a465298888aa791281?apiKey=fc85fc7c7f024bdfa1247fe0addfd173&"
                        alt=''
                        className="absolute h-full w-full object-cover object-center inset-0"
                    />
                    {title}
                </div>
            </div>
            <div className="self-center w-full max-w-[1200px] mt-20 max-md:max-w-full max-md:mt-10">
                <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                    <div className="flex flex-col items-stretch w-[43%] max-md:w-full max-md:ml-0">
                        <img
                            loading="lazy"
                            alt=''
                            srcSet={imageSrc}
                            className="aspect-square object-contain object-center w-full overflow-hidden max-md:max-w-full max-md:mt-10"
                        />
                    </div>
                    <div className="flex flex-col items-stretch w-[57%] ml-5 max-md:w-full max-md:ml-0">
                        <div className="items-start flex flex-col my-auto px-5 max-md:max-w-full max-md:mt-10">
                            <div className="text-neutral-900 text-left text-base font-semibold leading-8 tracking-wider self-stretch max-md:max-w-full">
                                {description}
                            </div>{" "}
                            <div className="text-neutral-900 text-left text-2xl font-extrabold tracking-widest capitalize self-stretch mt-3 max-md:max-w-full">
                                Benefits of {benefitTitle}
                            </div>{" "}
                            <div className='pl-5'>
                                {benefits.map((row, index) => {
                                    return (
                                        <div key={index} className="text-neutral-900 text-left text-base font-bold tracking-wider capitalize self-stretch mt-3 max-md:max-w-full">
                                            <ul className='list-disc'>
                                                <li>{row}</li>
                                            </ul>
                                        </div>
                                    )
                                })}


                            </div>
                            <button onClick={(e) => handleServices(e)} className="text-white text-left text-base font-medium uppercase whitespace-nowrap justify-center items-stretch rounded bg-[#353B4A] mt-9 px-12 py-4 self-start max-md:px-5">
                                Book Appointment
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServiceComponent