import React from 'react'


const Location = () => {
    const lat = 42.74672653504422;
    const lng = -73.75757642358819

    const embedUrl = `https://maps.google.com?q=${lat},${lng}&output=embed&zoom=2`;

    return (
        <div className='w-full'>
            <div className="flex-col overflow-hidden relative flex min-h-[600px] justify-center pointer-events-auto px-16 py-12 items-end max-md:px-5">
                <div className="absolute h-full w-full object-center inset-0">
                    <a href={embedUrl} target="_blank" rel="noopener noreferrer">
                        <iframe
                            title="Google Map"
                            width="100%"
                            height="600px"
                            style={{ border: 0 }}
                            src={embedUrl}
                            allowFullScreen
                        ></iframe>
                    </a>
                </div>
                <div className="absolute top-5 right-0 bg-[#ffffff] shadow-md justify-center items-stretch backdrop-blur-[2px] flex lg:w-[380px] md:w-[380px] sm:w-[200px] max-w-full flex-col mr-24 mb-9 lg:p-8 md:p-8 sm:p-4 rounded-xl max-md:mr-2.5 max-md:px-5">
                    <div className="text-neutral-900 lg:text-2xl md:text-2xl sm:text-xl font-semibold whitespace-nowrap justify-center items-center pb-2 px-16 border-b-black border-b-opacity-50 border-b border-solid max-md:px-5 mt-2">
                        Salon Hours
                    </div>
                    <div className="text-neutral-900 text-left lg:text-xl md:text-xl sm:text-sm  font-medium capitalize lg:mt-3 md:mt-6 sm:mt-2">
                        Monday 10:30 AM–7 PM
                    </div>
                    <div className="text-neutral-900 text-left lg:text-xl md:text-xl sm:text-sm font-medium capitalize lg:mt-3 md:mt-6 sm:mt-2">
                        Tuesday 10:30 AM–7 PM
                    </div>
                    <div className="text-neutral-900 text-left lg:text-xl md:text-xl sm:text-sm font-medium capitalize lg:mt-3 md:mt-6 sm:mt-2">
                        Wednesday 10:30 AM–7 PM
                    </div>
                    <div className="text-neutral-900 text-left lg:text-xl md:text-xl sm:text-sm font-medium capitalize lg:mt-3 md:mt-6 sm:mt-2">
                        Thursday 10:30 AM–7 PM
                    </div>
                    <div className="text-neutral-900 text-left lg:text-xl md:text-xl sm:text-sm font-medium capitalize lg:mt-3 md:mt-6 sm:mt-2">
                        Friday 10:30 AM–7 PM
                    </div>
                    <div className="text-neutral-900 text-left lg:text-xl md:text-xl sm:text-sm font-medium capitalize lg:mt-3 md:mt-6 sm:mt-2">
                        Saturday 10:30 AM–5 PM
                    </div>
                    <div className="text-neutral-900 text-left lg:text-xl md:text-xl sm:text-sm font-medium capitalize lg:mt-3 md:mt-6 sm:mt-2 mb-2">
                        Sunday 10:30 AM–4 PM
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Location