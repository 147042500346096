import { Fragment, useState } from 'react'
import { Dialog, Disclosure, Popover, Transition } from '@headlessui/react'
import {
    Bars3Icon,
    XMarkIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon } from '@heroicons/react/20/solid'

const serviceMenu = [
    { name: 'Threading', href: '/threading', current: true },
    { name: 'Waxing', href: '/waxing', current: false },
    { name: 'Facial', href: '/facial', current: false },
    { name: 'Henna', href: '/henna', current: false },
    { name: 'Make-Up', href: '/make-up', current: false },
    { name: 'Hairstyle', href: '/hairstyle', current: false },
    { name: 'Eyelashes', href: '/eyelashes', current: false },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Header() {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)


    const handleServices = (e) => {
        e.preventDefault()
        window.open('https://book.appt.cm/#/business/3f5ba17a-6f3a-4d2d-bf03-4a99cc773e83/embed/view/task?page=task', '_blank');
    }

    return (
        <header className="bg-[#353B4A] z-10 w-full sticky top-0">
            <nav className="mx-auto flex items-center justify-between p-4 lg:px-6" aria-label="Global">
                <div className="flex lg:flex-1">
                    <a href="/#" className="-m-1.5 p-1.5">
                        <img
                            className="h-12 w-12"
                            src="./assets/images/logo.png"
                            alt="Your Company"
                        />
                    </a>
                </div>
                <div className="flex lg:hidden">
                    <button
                        type="button"
                        className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white"
                        onClick={() => setMobileMenuOpen(true)}
                    >
                        <span className="sr-only">Open main menu</span>
                        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
                <Popover.Group className="hidden lg:flex items-center lg:gap-x-12">
                    <a href="/" className="text-white text-sm font-semibold leading-6">
                        Home
                    </a>
                    <Popover className="relative z-50	">
                        <Popover.Button className="flex bottom-0 focus:outline-none text-white items-center gap-x-1 text-sm font-semibold leading-6">
                            Services
                            <ChevronDownIcon className="h-5 w-5 flex-none text-white" aria-hidden="true" />
                        </Popover.Button>

                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                        >
                            <Popover.Panel className="absolute w-[150px] mt-3 rounded-xl  overflow-hidden bg-white shadow-lg">
                                <div className="">
                                    {serviceMenu.map((item) => (
                                        <div
                                            key={item.name}
                                            className="group relative flex items-center rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
                                        >

                                            <div className="flex-auto text-left">
                                                <a href={item.href} className="block font-semibold">
                                                    {item.name}
                                                    <span className="absolute inset-0" />
                                                </a>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </Popover.Panel>
                        </Transition>
                    </Popover>
                    <a href="/about-us" className="text-white text-sm font-semibold leading-6">
                        About Us
                    </a>
                    <a href="/contact-us" className="text-white text-sm font-semibold leading-6">
                        Contact Us
                    </a>
                    <button onClick={(e) => handleServices(e)} className="text-[#121212] text-base font-medium uppercase whitespace-nowrap justify-center items-stretch rounded bg-white hover:bg-gray-100 px-5 py-2">
                        Book Appointment
                    </button>
                </Popover.Group>
            </nav>
            <Dialog as="div" className="lg:hidden bg-[rgb(30,70,170)]" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                <div className="fixed inset-0 z-10" />
                <Dialog.Panel className="bg-[#353B4A] fixed inset-y-0 right-0 z-10 w-full overflow-y-auto text-white px-6 py-6 sm:ring-1 sm:ring-gray-900/10">
                    <div className="flex items-center justify-between">
                        <a href="/" className="-m-1.5 p-1.5">
                            <img
                                className="h-8 w-auto"
                                src="./assets/images/logo.png"
                                alt=""
                            />
                        </a>
                        <button
                            type="button"
                            className="-m-2.5 rounded-md p-2.5 text-white"
                            onClick={() => setMobileMenuOpen(false)}
                        >
                            <span className="sr-only">Close menu</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                    <div className="mt-6 flow-root">
                        <div className="-my-6 divide-y divide-gray-500/10">
                            <div className="space-y-2 py-6">
                                <a
                                    href="/"
                                    className="-mx-3 text-white block rounded-lg px-3 py-2 text-base font-semibold leading-7 hover:bg-gray-50 hover:text-black"
                                >
                                    Home
                                </a>
                                <Disclosure as="div" className="-mx-3">
                                    {({ open }) => (
                                        <>
                                            <Disclosure.Button className="flex w-full text-white items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 ">
                                                Services
                                                <ChevronDownIcon
                                                    className={classNames(open ? 'rotate-180' : '', 'h-5 w-5 flex-none')}
                                                    aria-hidden="true"
                                                />
                                            </Disclosure.Button>
                                            <Disclosure.Panel className="mt-2 space-y-2 bg-white text-black rounded-xl">
                                                {serviceMenu.map((item) => (
                                                    <Disclosure.Button
                                                        key={item.name}
                                                        as="a"
                                                        href={item.href}
                                                        className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7  hover:bg-gray-50"
                                                    >
                                                        {item.name}
                                                    </Disclosure.Button>
                                                ))}
                                            </Disclosure.Panel>
                                        </>
                                    )}
                                </Disclosure>

                                <a
                                    href="/about-us"
                                    className="-mx-3 text-white block rounded-lg px-3 py-2 text-base font-semibold leading-7 hover:bg-white hover:text-black"
                                >
                                    About Us
                                </a>
                                <a
                                    href="/contact-us"
                                    className="-mx-3 block text-white rounded-lg px-3 py-2 text-base font-semibold leading-7 hover:bg-white hover:text-black"
                                >
                                    Contact Us
                                </a>
                                <button onClick={(e) => handleServices(e)} className="text-[#121212] text-base w-full font-medium uppercase whitespace-nowrap justify-center items-stretch rounded bg-white px-5 py-2">
                                    Book Appointment
                                </button>
                            </div>
                        </div>
                    </div>
                </Dialog.Panel>
            </Dialog>
        </header>
    )
}