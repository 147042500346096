import React from 'react'
import ServiceComponent from './ServiceComponent';

const threadingData = {
    title: 'Facial',
    description:
        "Step into a world of refined beauty with our specialized facial treatment tailored for women. Experience the gentle touch of our precise technique, unveiling a velvety smooth complexion. Witness the subtle transformation, bidding farewell to unwanted elements, and welcoming the grace of a refreshed and polished facial appearance.",
    benefits: [
        'Refined Texture: Our precise technique enhances the texture of your facial skin, leaving it velvety smooth.',
        'Subtle Transformation: Witness a gradual reduction in unwanted elements, revealing a refreshed and polished facial appearance.',
        'Long-lasting Elegance: Enjoy the enduring effects of our treatment, ensuring a sustained and timeless radiance.',
    ],
    imageSrc: './assets/images/facial.png',
    benefitTitle: 'Facial'
};




const Facial = () => {
    return (
        <ServiceComponent {...threadingData} />
    )
}

export default Facial