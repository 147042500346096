import React from 'react'
import ContactForm from './ContactForm'
import Location from './Location'

const ContactUs = () => {
    return (
        <div>
            <div
                className="flex flex-col items-center"
            >
                <div className="bg-red-100 self-stretch flex w-full flex-col justify-center items-stretch max-md:max-w-full">
                    <div className="flex-col text-neutral-900 text-5xl font-semibold tracking-widest relative whitespace-nowrap overflow-hidden min-h-[229px] w-full justify-center items-center px-16 py-20 max-md:text-4xl max-md:max-w-full max-md:px-5">
                        <img
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/28b59f634e417580eb8973c69d788eddab811c68bba99ecbdcff763a6fdf93cc?apiKey=fc85fc7c7f024bdfa1247fe0addfd173&"
                            alt=''
                            className="absolute h-full w-full object-cover object-center inset-0"
                        />
                        <div className='text-neutral-900 text-opacity-70 font-medium relative whitespace-nowrap mt-2'>INFORMATION</div>
                        Contact Us
                    </div>
                </div>
            </div>
            <ContactForm />
            <Location />
        </div>
    )
}

export default ContactUs