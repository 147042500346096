import React from 'react'

const PricingSection = () => {
    return (
        <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div className="items-center flex flex-col justify-center px-16 py-8 max-md:px-5">
                <div className="flex w-full flex-col items-center max-md:max-w-full">
                    <div className="text-[#353B4A]-600 text-center text-4xl font-bold capitalize whitespace-nowrap">
                        Our pricing
                    </div>
                    <div className="justify-between shadow-lg bg-white self-stretch mt-16 px-6 lg:py-3 md:py-0 sm:py-0 rounded-2xl max-md:max-w-full max-md:mt-10">
                        <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                            <div className="flex flex-col items-stretch w-[54%] max-md:w-full max-md:ml-0">
                                <img
                                    loading="lazy"
                                    srcSet="./assets/images/belt-waxing.png"
                                    className="aspect-[0.61] h-full object-contain object-center w-full overflow-hidden max-md:max-w-full max-md:mt-2"
                                    alt=''
                                />
                            </div>
                            <div className="flex flex-col items-stretch w-[50%] ml-5 mb-3 max-md:w-full max-md:ml-0">
                                <div className="items-stretch flex flex-col max-md:max-w-full max-md:mt-10">
                                    <div className="justify-between flex gap-5 items-start max-md:max-w-full max-md:flex-wrap">
                                        <div className="text-[#141414] text-center text-3xl font-semibold whitespace-nowrap">
                                            Waxing
                                        </div>
                                        <div className="text-[#141414] text-center text-3xl font-semibold leading-9 tracking-tight self-stretch whitespace-nowrap">
                                            Price
                                        </div>
                                    </div>
                                    <div className="justify-between items-stretch flex gap-5 mt-2.5 pb-1 border-b-opacity-10 border-b border-solid max-md:max-w-full max-md:flex-wrap">
                                        <div className="text-[#141414] text-left text-md grow shrink basis-auto">
                                            Eyebrows
                                        </div>
                                        <div className="text-[#141414] text-left text-md tracking-wider">
                                            $ 12
                                        </div>
                                    </div>
                                    <div className="justify-between items-stretch flex gap-5 mt-2.5 pb-1 border-b-opacity-10 border-b border-solid max-md:max-w-full max-md:flex-wrap">
                                        <div className="text-[#141414] text-left text-md grow shrink basis-auto">
                                            Upper lip
                                        </div>
                                        <div className="text-[#141414] text-left text-md tracking-wider">
                                            $ 05
                                        </div>
                                    </div>
                                    <div className="justify-between items-stretch flex gap-5 mt-2.5 pb-1 border-b-opacity-10 border-b border-solid max-md:max-w-full max-md:flex-wrap">
                                        <div className="text-[#141414] text-left text-md grow shrink basis-auto">
                                            Chin
                                        </div>
                                        <div className="text-[#141414] text-left text-md tracking-wider whitespace-nowrap">
                                            $ 08
                                        </div>
                                    </div>
                                    <div className="justify-between items-stretch flex gap-5 mt-2.5 pb-1 border-b-opacity-10 border-b border-solid max-md:max-w-full max-md:flex-wrap">
                                        <div className="text-[#141414] text-left text-md grow shrink basis-auto">
                                            Forehead
                                        </div>
                                        <div className="text-[#141414] text-left text-md tracking-wider">
                                            $ 05
                                        </div>
                                    </div>
                                    <div className="justify-between items-stretch flex gap-5 mt-2.5 pb-1 border-b-opacity-10 border-b border-solid max-md:max-w-full max-md:flex-wrap">
                                        <div className="text-[#141414] text-left text-md grow shrink basis-auto">
                                            Cheeks
                                        </div>
                                        <div className="text-[#141414] text-left text-md tracking-wider">
                                            $ 08
                                        </div>
                                    </div>
                                    <div className="justify-between items-stretch flex gap-5 mt-2.5 pb-1 border-b-opacity-10 border-b border-solid max-md:max-w-full max-md:flex-wrap">
                                        <div className="text-[#141414] text-left text-md grow shrink basis-auto">
                                            Side burns
                                        </div>
                                        <div className="text-[#141414] text-left text-md tracking-wider">
                                            $ 08
                                        </div>
                                    </div>
                                    <div className="justify-between items-stretch flex gap-5 mt-2.5 pb-1 border-b-opacity-10 border-b border-solid max-md:max-w-full max-md:flex-wrap">
                                        <div className="text-[#141414] text-left text-md grow shrink basis-auto">
                                            Neck
                                        </div>
                                        <div className="text-[#141414] text-left text-md tracking-wider whitespace-nowrap">
                                            $ 8+
                                        </div>
                                    </div>
                                    <div className="justify-between items-stretch flex gap-5 mt-2.5 pb-1 border-b-opacity-10 border-b border-solid max-md:max-w-full max-md:flex-wrap">
                                        <div className="text-[#141414] text-left text-md grow shrink basis-auto">
                                            Unibrow
                                        </div>
                                        <div className="text-[#141414] text-left text-md tracking-wider">
                                            $ 05
                                        </div>
                                    </div>
                                    <div className="justify-between items-stretch flex gap-5 mt-2.5 pb-1 border-b-opacity-10 border-b border-solid max-md:max-w-full max-md:flex-wrap">
                                        <div className="text-[#141414] text-left text-md grow shrink basis-auto">
                                            Nose
                                        </div>
                                        <div className="text-[#141414] text-left text-md tracking-wider">
                                            $ 12
                                        </div>
                                    </div>
                                    <div className="justify-between items-stretch flex gap-5 mt-2.5 pb-1 border-b-opacity-10 border-b border-solid max-md:max-w-full max-md:flex-wrap">
                                        <div className="text-[#141414] text-left text-md grow shrink basis-auto">
                                            Half arms
                                        </div>
                                        <div className="text-[#141414] text-left text-md tracking-wider whitespace-nowrap">
                                            $ 25
                                        </div>
                                    </div>
                                    <div className="justify-between items-stretch flex gap-5 mt-2.5 pb-1 border-b-opacity-10 border-b border-solid max-md:max-w-full max-md:flex-wrap">
                                        <div className="text-[#141414] text-left text-md grow shrink basis-auto">
                                            Full arms
                                        </div>
                                        <div className="text-[#141414] text-left text-md tracking-wider whitespace-nowrap">
                                            $ 35
                                        </div>
                                    </div>
                                    <div className="justify-between items-stretch flex gap-5 mt-2.5 pb-1 border-b-opacity-10 border-b border-solid max-md:max-w-full max-md:flex-wrap">
                                        <div className="text-[#141414] text-left text-md grow shrink basis-auto">
                                            Half legs
                                        </div>
                                        <div className="text-[#141414] text-left text-md tracking-wider whitespace-nowrap">
                                            $ 35
                                        </div>
                                    </div>
                                    <div className="justify-between items-stretch flex gap-5 mt-2.5 pb-1 border-b-opacity-10 border-b border-solid max-md:max-w-full max-md:flex-wrap">
                                        <div className="text-[#141414] text-left text-md grow shrink basis-auto">
                                            Full legs
                                        </div>
                                        <div className="text-[#141414] text-left text-md tracking-wider whitespace-nowrap">
                                            $ 50
                                        </div>
                                    </div>
                                    <div className="justify-between items-stretch flex gap-5 mt-2.5 pb-1 border-b-opacity-10 border-b border-solid max-md:max-w-full max-md:flex-wrap">
                                        <div className="text-[#141414] text-left text-md grow shrink basis-auto">
                                            Under arms
                                        </div>
                                        <div className="text-[#141414] text-left text-md tracking-wider">
                                            $ 15
                                        </div>
                                    </div>
                                    <div className="justify-between items-stretch flex gap-5 mt-2.5 pb-1 border-b-opacity-10 border-b border-solid max-md:max-w-full max-md:flex-wrap">
                                        <div className="text-[#141414] text-left text-md grow shrink basis-auto">
                                            Full face
                                        </div>
                                        <div className="text-[#141414] text-left text-md tracking-wider whitespace-nowrap">
                                            $ 40
                                        </div>
                                    </div>
                                    <div className="justify-between items-stretch flex gap-5 mt-2.5 pb-1 border-b-opacity-10 border-b border-solid max-md:max-w-full max-md:flex-wrap">
                                        <div className="text-[#141414] text-left text-md grow shrink basis-auto">
                                            Brazilian
                                        </div>
                                        <div className="text-[#141414] text-left text-md tracking-wider whitespace-nowrap">
                                            $ 45
                                        </div>
                                    </div>
                                    <div className="justify-between items-stretch flex gap-5 mt-2.5 pb-1 border-b-opacity-10 border-b border-solid max-md:max-w-full max-md:flex-wrap">
                                        <div className="text-[#141414] text-left text-md grow shrink basis-auto">
                                            Bikini line
                                        </div>
                                        <div className="text-[#141414] text-left text-md tracking-wider whitespace-nowrap">
                                            $ 25
                                        </div>
                                    </div>
                                    <div className="justify-between items-stretch flex gap-5 mt-2.5 pb-1 border-b-opacity-10 border-b border-solid max-md:max-w-full max-md:flex-wrap">
                                        <div className="text-[#141414] text-left text-md grow shrink basis-auto">
                                            Back waxing
                                        </div>
                                        <div className="text-[#141414] text-left text-md tracking-wider whitespace-nowrap">
                                            $ 40
                                        </div>
                                    </div>
                                    <div className="justify-between items-stretch flex gap-5 mt-4 max-md:max-w-full max-md:flex-wrap">
                                        <div className="text-[#141414] text-left text-md grow shrink basis-auto">
                                            Stomach
                                        </div>
                                        <div className="text-[#141414] text-left text-md tracking-wider whitespace-nowrap">
                                            $ 30
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="justify-between shadow-lg bg-white self-stretch mt-5 lg:mt-16 px-6 md:py-0 sm:py-0 rounded-2xl max-md:max-w-full max-md:px-5">
                        <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0 mb-3">
                            <div className="flex order-2 lg:order-1 sm:order-2 flex-col items-stretch w-[46%] max-md:w-full max-md:ml-0">
                                <div className="items-stretch flex flex-col max-md:max-w-full max-md:mt-2">
                                    <div className="justify-between flex gap-5 items-start max-md:max-w-full max-md:flex-wrap">
                                        <div className="text-[#141414] text-center text-3xl font-semibold whitespace-nowrap">
                                            Facial
                                        </div>
                                        <div className="text-[#141414] text-center text-3xl font-semibold leading-9 tracking-tight self-stretch whitespace-nowrap">
                                            Price
                                        </div>
                                    </div>
                                    <div className="justify-between items-stretch flex gap-5 mt-2.5 pb-1 border-b-opacity-10 border-b border-solid max-md:max-w-full max-md:flex-wrap">
                                        <div className="text-[#141414] text-left text-md leading-7 grow shrink basis-auto">
                                            Face cleansing and toning
                                        </div>
                                        <div className="text-[#141414] text-left text-md leading-7 tracking-wider whitespace-nowrap">
                                            $ 20
                                        </div>
                                    </div>
                                    <div className="justify-between items-stretch flex gap-5 mt-2.5 pb-1 border-b-opacity-10 border-b border-solid max-md:max-w-full max-md:flex-wrap">
                                        <div className="text-[#141414] text-left text-md leading-7 grow shrink basis-auto">
                                            Face cleansing and mask
                                        </div>
                                        <div className="text-[#141414] text-left text-md leading-7 tracking-wider">
                                            $ 15
                                        </div>
                                    </div>
                                    <div className="justify-between items-stretch flex gap-5 mt-2.5 pb-1 border-b-opacity-10 border-b border-solid max-md:max-w-full max-md:flex-wrap">
                                        <div className="text-[#141414] text-left text-md leading-7 grow shrink basis-auto">
                                            Black heads removal
                                        </div>
                                        <div className="text-[#141414] text-left text-md leading-7 tracking-wider whitespace-nowrap">
                                            $ 20
                                        </div>
                                    </div>
                                    <div className="justify-between items-stretch flex gap-5 mt-2.5 pb-1 border-b-opacity-10 border-b border-solid max-md:max-w-full max-md:flex-wrap">
                                        <div className="text-[#141414] text-left text-md leading-7 grow shrink basis-auto">
                                            Shiner bleach
                                        </div>
                                        <div className="text-[#141414] text-left text-md leading-7 tracking-wider whitespace-nowrap">
                                            $ 20
                                        </div>
                                    </div>
                                    <div className="justify-between items-stretch flex gap-5 mt-2.5 pb-1 border-b-opacity-10 border-b border-solid max-md:max-w-full max-md:flex-wrap">
                                        <div className="text-[#141414] text-left text-md leading-7 grow shrink basis-auto">
                                            Basic facial
                                        </div>
                                        <div className="text-[#141414] text-left text-md leading-7 tracking-wider whitespace-nowrap">
                                            $ 60
                                        </div>
                                    </div>
                                    <div className="justify-between items-stretch flex gap-5 mt-2.5 pb-1 border-b-opacity-10 border-b border-solid max-md:max-w-full max-md:flex-wrap">
                                        <div className="text-[#141414] text-left text-md leading-7 grow shrink basis-auto">
                                            Ditan facial
                                        </div>
                                        <div className="text-[#141414] text-left text-md leading-7 tracking-wider whitespace-nowrap">
                                            $ 55
                                        </div>
                                    </div>
                                    <div className="justify-between items-stretch flex gap-5 mt-2.5 pb-1 border-b-opacity-10 border-b border-solid max-md:max-w-full max-md:flex-wrap">
                                        <div className="text-[#141414] text-left text-md leading-7 grow shrink basis-auto">
                                            Back facial
                                        </div>
                                        <div className="text-[#141414] text-left text-md leading-7 tracking-wider whitespace-nowrap">
                                            $ 40
                                        </div>
                                    </div>
                                    <div className="justify-between items-stretch flex gap-5 mt-2.5 pb-1 border-b-opacity-10 border-b border-solid max-md:max-w-full max-md:flex-wrap">
                                        <div className="text-[#141414] text-left text-md leading-7 grow shrink basis-auto">
                                            LED light therapy
                                        </div>
                                        <div className="text-[#141414] text-left text-md leading-7 tracking-wider whitespace-nowrap">
                                            $ 20
                                        </div>
                                    </div>
                                    <div className="justify-between items-stretch flex gap-5 mt-2.5 pb-1 border-b-opacity-10 border-b border-solid max-md:max-w-full max-md:flex-wrap">
                                        <div className="text-[#141414] text-left text-md leading-7 grow shrink basis-auto">
                                            High frequency treatment
                                        </div>
                                        <div className="text-[#141414] text-left text-md leading-7 tracking-wider whitespace-nowrap">
                                            $ 20
                                        </div>
                                    </div>
                                    <div className="justify-between items-stretch flex gap-5 mt-2.5 pb-1 border-b-opacity-10 border-b border-solid max-md:max-w-full max-md:flex-wrap">
                                        <div className="text-[#141414] text-left text-md leading-7 grow shrink basis-auto">
                                            30 minutes custom
                                        </div>
                                        <div className="text-[#141414] text-left text-md leading-7 tracking-wider whitespace-nowrap">
                                            $ 40
                                        </div>
                                    </div>
                                    <div className="justify-between items-stretch flex gap-5 mt-2.5 pb-1 border-b-opacity-10 border-b border-solid max-md:max-w-full max-md:flex-wrap">
                                        <div className="text-[#141414] text-left text-md leading-7 grow shrink basis-auto">
                                            60 minutes custom
                                        </div>
                                        <div className="text-[#141414] text-left text-md leading-7 tracking-wider whitespace-nowrap">
                                            $ 55
                                        </div>
                                    </div>
                                    <div className="justify-between items-stretch flex gap-5 mt-2.5 pb-1 border-b-opacity-10 border-b border-solid max-md:max-w-full max-md:flex-wrap">
                                        <div className="text-[#141414] text-left text-md leading-7 grow shrink basis-auto">
                                            75 minutes custom
                                        </div>
                                        <div className="text-[#141414] text-left text-md leading-7 tracking-wider whitespace-nowrap">
                                            $ 65
                                        </div>
                                    </div>
                                    <div className="text-[#141414] text-left text-md leading-7 justify-between items-stretch mt-2.5 pb-1 border-b-opacity-10 border-b border-solid max-md:max-w-full">
                                        Hair massage (oiling optional)
                                    </div>
                                    <div className="text-[#141414] text-left text-md leading-7 justify-between items-stretch mt-2.5 pb-1 border-b-opacity-10 border-b border-solid max-md:max-w-full">
                                        Neck massage
                                    </div>
                                    <div className="text-[#141414] text-left text-md leading-7 justify-between items-stretch mt-2.5 pb-1 border-b-opacity-10 border-b border-solid max-md:max-w-full">
                                        Hand massage
                                    </div>
                                    <div className="text-[#141414] text-left text-md leading-7 mt-4 max-md:max-w-full">
                                        Back massage
                                    </div>
                                </div>
                            </div>
                            <div className="flex order-1 lg:order-2 sm:order-1 flex-col items-stretch w-[54%] ml-5 max-md:w-full max-md:ml-0 ">
                                <img
                                    loading="lazy"
                                    srcSet="./assets/images/facial-img.png"
                                    alt=''
                                    className="aspect-[0.71] object-contain object-center w-full overflow-hidden max-md:max-w-full max-md:mt-3"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="justify-between shadow-lg bg-white self-stretch mt-16 px-6 lg:py-3 md:py-0 sm:py-0 rounded-2xl max-md:max-w-full max-md:mt-10 max-md:px-5">
                        <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                            <div className="flex flex-col items-stretch w-[54%] max-md:w-full max-md:ml-0">
                                <img
                                    loading="lazy"
                                    srcSet="./assets/images/about.png"
                                    alt=''
                                    className="aspect-[1.01] object-contain object-center w-full overflow-hidden max-md:max-w-full max-md:mt-3"
                                />
                            </div>
                            <div className="flex flex-col items-stretch w-[46%] ml-5 max-md:w-full max-md:ml-0 mb-3">
                                <div className="items-stretch flex flex-col max-md:max-w-full max-md:mt-10">
                                    <div className="justify-between flex gap-5 items-start max-md:max-w-full max-md:flex-wrap">
                                        <div className="text-[#141414] text-center text-3xl font-semibold whitespace-nowrap">
                                            Threading
                                        </div>
                                        <div className="text-[#141414] text-center text-3xl font-semibold leading-9 tracking-tight self-stretch whitespace-nowrap">
                                            Price
                                        </div>
                                    </div>
                                    <div className="justify-between items-stretch flex gap-5 mt-2.5 pb-1 border-b-opacity-10 border-b border-solid max-md:max-w-full max-md:flex-wrap">
                                        <div className="text-[#141414] text-left leading-7 grow shrink basis-auto">
                                            Eyebrows
                                        </div>
                                        <div className="text-[#141414] text-left leading-7 tracking-wider">
                                            $ 10
                                        </div>
                                    </div>
                                    <div className="justify-between items-stretch flex gap-5 mt-2.5 pb-1 border-b-opacity-10 border-b border-solid max-md:max-w-full max-md:flex-wrap">
                                        <div className="text-[#141414] text-left text-md leading-7 grow shrink basis-auto">
                                            Upper Lip
                                        </div>
                                        <div className="text-[#141414] text-left text-md leading-7 tracking-wider">
                                            $ 05
                                        </div>
                                    </div>
                                    <div className="justify-between items-stretch flex gap-5 mt-2.5 pb-1 border-b-opacity-10 border-b border-solid max-md:max-w-full max-md:flex-wrap">
                                        <div className="text-[#141414] text-left text-md leading-7 grow shrink basis-auto">
                                            Lower Lip
                                        </div>
                                        <div className="text-[#141414] text-left text-md leading-7 tracking-wider">
                                            $ 05
                                        </div>
                                    </div>
                                    <div className="justify-between items-stretch flex gap-5 mt-2.5 pb-1 border-b-opacity-10 border-b border-solid max-md:max-w-full max-md:flex-wrap">
                                        <div className="text-[#141414] text-left text-md leading-7 grow shrink basis-auto">
                                            Chin
                                        </div>
                                        <div className="text-[#141414] text-left text-md leading-7 tracking-wider whitespace-nowrap">
                                            $ 8+
                                        </div>
                                    </div>
                                    <div className="justify-between items-stretch flex gap-5 mt-2.5 pb-1 border-b-opacity-10 border-b border-solid max-md:max-w-full max-md:flex-wrap">
                                        <div className="text-[#141414] text-left text-md leading-7 grow shrink basis-auto">
                                            Forehead
                                        </div>
                                        <div className="text-[#141414] text-left text-md leading-7 tracking-wider">
                                            $ 05
                                        </div>
                                    </div>
                                    <div className="justify-between items-stretch flex gap-5 mt-2.5 pb-1 border-b-opacity-10 border-b border-solid max-md:max-w-full max-md:flex-wrap">
                                        <div className="text-[#141414] text-left text-md leading-7 grow shrink basis-auto">
                                            Cheeks
                                        </div>
                                        <div className="text-[#141414] text-left text-md leading-7 tracking-wider">
                                            $ 08
                                        </div>
                                    </div>
                                    <div className="justify-between items-stretch flex gap-5 mt-2.5 pb-1 border-b-opacity-10 border-b border-solid max-md:max-w-full max-md:flex-wrap">
                                        <div className="text-[#141414] text-left text-md leading-7 grow shrink basis-auto">
                                            Side burns
                                        </div>
                                        <div className="text-[#141414] text-left text-md leading-7 tracking-wider">
                                            $ 09
                                        </div>
                                    </div>
                                    <div className="justify-between items-stretch flex gap-5 mt-2.5 pb-1 border-b-opacity-10 border-b border-solid max-md:max-w-full max-md:flex-wrap">
                                        <div className="text-[#141414] text-left text-md leading-7 grow shrink basis-auto">
                                            Neck
                                        </div>
                                        <div className="text-[#141414] text-left text-md leading-7 tracking-wider whitespace-nowrap">
                                            $ 8+
                                        </div>
                                    </div>
                                    <div className="justify-between items-stretch flex gap-5 mt-2.5 pb-1 border-b-opacity-10 border-b border-solid max-md:max-w-full max-md:flex-wrap">
                                        <div className="text-[#141414] text-left text-md leading-7 grow shrink basis-auto">
                                            Unibrows
                                        </div>
                                        <div className="text-[#141414] text-left text-md leading-7 tracking-wider">
                                            $ 05
                                        </div>
                                    </div>
                                    <div className="justify-between items-stretch flex gap-5 mt-2.5 pb-1 border-b-opacity-10 border-b border-solid max-md:max-w-full max-md:flex-wrap">
                                        <div className="text-[#141414] text-left text-md leading-7 grow shrink basis-auto">
                                            Full face
                                        </div>
                                        <div className="text-[#141414] text-left text-md leading-7 tracking-wider whitespace-nowrap">
                                            $ 35
                                        </div>
                                    </div>
                                    <div className="justify-between items-stretch flex gap-5 mt-4 max-md:max-w-full max-md:flex-wrap">
                                        <div className="text-[#141414] text-left text-md leading-7 grow shrink basis-auto">
                                            Eyebrow tinting
                                        </div>
                                        <div className="text-[#141414] text-left text-md leading-7 tracking-wider whitespace-nowrap">
                                            $ 20
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-full max-w-[1076px] mt-5 max-md:max-w-full max-md:mt-3">
                        <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                            <div className="flex flex-col items-stretch w-6/12 max-md:w-full max-md:ml-0">
                                <div className="items-stretch shadow-lg bg-white flex grow flex-col w-full px-5 py-6 rounded-2xl max-md:max-w-full max-md:mt-3 max-md:pl-5">
                                    <div className="justify-center items-stretch bg-white flex flex-col py-px max-md:max-w-full">
                                        <img
                                            loading="lazy"
                                            srcSet="./assets/images/henna.png"
                                            alt=''
                                            className="aspect-square object-contain object-center w-full overflow-hidden max-md:max-w-full"
                                        />
                                    </div>
                                    <div className="items-stretch text-left flex flex-col mt-3 p-3 max-md:max-w-full">
                                        <div className="text-slate-900 text-xl font-semibold max-md:max-w-full">
                                            Henna tattoo
                                        </div>
                                        <div className="text-slate-900 text-xl font-semibold mt-3 max-md:max-w-full">
                                            Bridal henna
                                        </div>
                                        <div className="text-slate-900 text-xl font-semibold mt-3 max-md:max-w-full">
                                            Party orders
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col items-stretch w-6/12 ml-5 max-md:w-full max-md:ml-0">
                                <div className="items-stretch shadow-lg bg-white flex grow flex-col w-full px-5 py-6 rounded-2xl max-md:max-w-full max-md:mt-10 max-md:pl-5">
                                    <div className="justify-center items-stretch bg-white flex flex-col py-px max-md:max-w-full">
                                        <img
                                            loading="lazy"
                                            alt=''
                                            srcSet="./assets/images/beautygirl.png"
                                            className="aspect-square object-contain object-center w-full overflow-hidden max-md:max-w-full"
                                        />
                                    </div>
                                    <div className="items-stretch text-left flex flex-col mt-3 p-3 max-md:max-w-full">
                                        <div className="text-slate-900 text-xl font-semibold max-md:max-w-full">
                                            Make up
                                        </div>
                                        <div className="text-slate-900 text-xl font-semibold mt-3 max-md:max-w-full">
                                            Saree draping
                                        </div>
                                        <div className="text-slate-900 text-xl font-semibold mt-3 max-md:max-w-full">
                                            Hairstyles
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PricingSection