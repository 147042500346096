import React from 'react'
import ServiceComponent from './ServiceComponent'

const threadingData = {
    title: 'Threading',
    description:
        "Embark on a journey of refined beauty with our threading services, an age-old artistry that transcends mere hair removal. At Akshi Beauty Salon, we celebrate the precision and elegance of threading, offering you more than just an eyebrow shaping session – it's a transformative experience.",
    benefits: [
        'Exclusively chemical free',
        'More precise and defined shape',
        'Shape of your choice',
        'Last longer, 15-25 days',
        'Inexpensive',
    ],
    imageSrc: './assets/images/about.png',
    benefitTitle: 'Threading'
};


const Threading = () => {

    return (
        <ServiceComponent {...threadingData} />
    )
}

export default Threading