import React from 'react'
import ServiceComponent from './ServiceComponent';

const threadingData = {
    title: 'HairStyle',
    description:
        "Embark on a journey of transformation at Akshi Salon, where hairstyling is an art form. Our team of skilled hairstylists is dedicated to bringing out the best in your hair, creating styles that not only enhance your beauty but also reflect your unique personality. From classic cuts to trendy transformations, we are here to redefine your hair journey.",
    benefits: [
        'Tailored Precision: Experience the art of hairstyling with tailored precision. Our stylists are experts in understanding your individual features, recommending styles that complement your face shape, lifestyle, and personal preferences.',
        'Our Expertise: Our team of hairstylists is passionate about creating looks that enhance your natural beauty. Whether you are looking for a fresh cut, vibrant color, or a complete makeover, our experts are here to turn your hair dreams into reality.',
        'Book Your Hairstyling Experience: Ready for a hair transformation? Schedule an appointment with our hairstyling experts at Akshi Salon and discover a world of possibilities for your hair. Your journey to stunning, healthy, and stylish hair begins here.',
    ],
    imageSrc: './assets/images/hairstyle.png',
    benefitTitle: 'HairStyle'
};


const HairStyle = () => {
    return (
        <ServiceComponent {...threadingData} />
    )
}

export default HairStyle