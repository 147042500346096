import React, { useState } from 'react'

const Footer = () => {
    const [showError, setShowError] = useState(false);

    const recipientEmail = 'akshibeautysalon@gmail.com';
    const gmailLink = `https://mail.google.com/mail/?view=cm&fs=1&to=${recipientEmail}`;

    const handleClickFb = () => {
        if (!showError) {
            window.open('https://www.facebook.com/AkshiBeautyProducts?mibextid=LQQJ4d', '_blank');
        } else {
            alert('This is a browser feature intended for developers. Do not use this for malicious purposes.');
        }
    };
    const handleClickInstagram = () => {
        if (!showError) {
            window.open('https://www.instagram.com/akshi_beauty/', '_blank');
        } else {
            alert('This is a browser feature intended for developers. Do not use this for malicious purposes.');
        }
    };

    const openGoogleMap = () => {
        const lat = 42.74672653504422;
        const lng = -73.75757642358819;

        window.open(`https://www.google.com/maps?q=${lat},${lng}`, '_blank');
    };

    const phoneNumber = '+19739797640';

    const handleWhatsAppClick = () => {
        const url = `https://wa.me/${phoneNumber}`;
        window.open(url, '_blank');
    };

    return (
        <footer className=" text-white">
            <div className="bg-[#353B4A] flex flex-col justify-center items-stretch">
                <div className="flex-col overflow-hidden relative flex min-h-[421px] w-full items-stretch pt-12 lg:pt-12 md:pt-12 sm:pt-5 pb-7 px-5 max-md:max-w-full">
                    <img
                        loading="lazy"
                        srcSet="./assets/images/footer-bg.svg"
                        alt=''
                        className="absolute h-full w-full object-cover object-center inset-0"
                    />
                    <div className="relative self-center w-full max-w-[1200px] max-md:max-w-full">
                        <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                            <div className="flex flex-col  items-stretch w-3/12 max-md:w-full max-md:ml-0">
                                <div className="relative flex flex-col items-start max-md:mt-2">
                                    <div className='sm:text-center'>
                                        <img
                                            loading="lazy"
                                            alt=''
                                            srcSet="./assets/images/logo.png"
                                            className="aspect-[0.78] object-contain sm:flex sm:justify-center w-[100px] overflow-hidden max-w-full"
                                        />
                                    </div>
                                    <div className="items-stretch self-stretch flex gap-3 mt-5 pr-20 max-md:pr-5">
                                        <a
                                            href='https://www.facebook.com/AkshiBeautyProducts?mibextid=LQQJ4d'
                                            target='_blank'
                                            rel="noopener noreferrer"
                                            onClick={handleClickFb}
                                        >
                                            <img
                                                loading="lazy"
                                                srcSet="./assets/images/facebook.png"
                                                className="aspect-square object-contain object-center w-8 overflow-hidden shrink-0 max-w-full"
                                                alt=''
                                            />
                                        </a>
                                        <a
                                            href='https://www.facebook.com/AkshiBeautyProducts?mibextid=LQQJ4d'
                                            target='_blank'
                                            rel="noopener noreferrer"
                                            onClick={handleClickInstagram}
                                        >
                                            <img
                                                loading="lazy"
                                                srcSet="./assets/images/instagram.png"
                                                className="aspect-square object-contain object-center w-8 overflow-hidden shrink-0 max-w-full"
                                                alt=''
                                            />
                                        </a>
                                        <a
                                            href='https://www.facebook.com/AkshiBeautyProducts?mibextid=LQQJ4d'
                                            target='_blank'
                                            rel="noopener noreferrer"
                                            onClick={handleWhatsAppClick}
                                        >
                                            <img
                                                loading="lazy"
                                                srcSet="./assets/images/whatsapp.png"
                                                className="aspect-square object-contain object-center w-8 overflow-hidden shrink-0 max-w-full"
                                                alt=''
                                            />
                                        </a>
                                        <a
                                            href='https://www.google.com/maps?q=42.74672653504422,-73.75757642358819'
                                            target='_blank'
                                            rel="noopener noreferrer"
                                            onClick={openGoogleMap}
                                        >
                                            <img
                                                loading="lazy"
                                                srcSet="./assets/images/google-map.png"
                                                className="aspect-square object-contain object-center w-8 overflow-hidden shrink-0 max-w-full"
                                                alt=''
                                            />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col items-stretch w-9/12 ml-5 max-md:w-full max-md:ml-0">
                                <div className="relative grow max-md:max-w-full max-md:mt-2">
                                    <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                                        <div className="flex hidden lg:block md:block flex-col items-stretch w-[33%] max-md:w-full max-md:ml-0">
                                            <div className="items-stretch text-left relative flex flex-col pb-12 max-md:mt-8">
                                                <div className="text-white text-2xl font-semibold leading-8">
                                                    Quick Links
                                                </div>
                                                <a href="/" className="text-white text-sm font-semibold leading-6 mt-6">
                                                    Home
                                                </a>
                                                <a href="/about-us" className="text-white text-sm font-semibold leading-6 mt-3">
                                                    About Us
                                                </a>
                                            </div>
                                        </div>
                                        <div className="flex flex-col hidden lg:block md:block items-stretch w-[33%] ml-5 max-md:w-full max-md:ml-0">
                                            <div className="items-stretch text-left relative flex grow flex-col max-md:mt-8">
                                                <a href="/" className="text-white text-2xl font-semibold leading-8">
                                                    Services
                                                </a>
                                                <a href="/threading" className="text-white text-sm font-semibold leading-6 mt-3">
                                                    Threading
                                                </a>
                                                <a href="/waxing" className="text-white text-sm font-semibold leading-6 mt-3">
                                                    Waxing
                                                </a>
                                                <a href="/facial" className="text-white text-sm font-semibold leading-6 mt-3">
                                                    Facial
                                                </a>
                                                <a href="/make-up" className="text-white text-sm font-semibold leading-6 mt-3">
                                                    Make - Up
                                                </a>
                                                <a href="/hairstyle" className="text-white text-sm font-semibold leading-6 mt-3">
                                                    Hair styles
                                                </a>
                                                <a href="/eyelashes" className="text-white text-sm font-semibold leading-6 mt-3">
                                                    Eyelashes
                                                </a>
                                            </div>
                                        </div>
                                        <div className="flex flex-col items-stretch w-[33%] ml-5 max-md:w-full max-md:ml-0 mt-5 lg:mt-0 md:mt-0">
                                            <div className="items-stretch text-left relative flex flex-col">
                                                <div className="text-white text-2xl font-semibold leading-8">
                                                    Reach us
                                                </div>
                                                <div className="items-stretch flex gap-2 mt-6">
                                                    <img
                                                        loading="lazy"
                                                        srcSet='./assets/images/Message.svg'
                                                        alt=''
                                                        className="aspect-square object-contain object-center w-6 overflow-hidden shrink-0 max-w-full"
                                                    />
                                                    <a href={gmailLink} target='_blank' rel="noreferrer" className="text-white text-sm font-semibold leading-6">
                                                        akshibeautysalon@gmail.com
                                                    </a>
                                                </div>
                                                <div className="items-stretch flex gap-2 mt-6">
                                                    <img
                                                        loading="lazy"
                                                        alt=''
                                                        srcSet='./assets/images/Mobile.svg'
                                                        className="aspect-square object-contain object-center w-6 overflow-hidden shrink-0 max-w-full"
                                                    />
                                                    <div className="text-white text-sm font-semibold leading-6">
                                                        +1 (845) 475-3025
                                                    </div>
                                                </div>
                                                <div className="flex gap-2 mt-6 items-start">
                                                    <img
                                                        loading="lazy"
                                                        alt=''
                                                        srcSet='./assets/images/locationicon.svg'
                                                        className="aspect-square object-contain object-center w-6 overflow-hidden shrink-0 max-w-full"
                                                    />
                                                    <div className="text-white text-sm font-semibold leading-6 capitalize">
                                                        457 Troy-Schenectady Rd, Latham, NY 12110
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="relative flex min-h-[1px] w-full flex-col mt-2 border-t-zinc-100 border-t border-solid max-md:max-w-full" />
                    {/* <div className=''>
                        <div className="items-stretch flex justify-center self-stretch gap-3 mt-5 pr-20 max-md:pr-5">
                            <a
                                href='https://www.facebook.com/AkshiBeautyProducts?mibextid=LQQJ4d'
                                target='_blank'
                                rel="noopener noreferrer"
                                onClick={handleClickFb}
                            >
                                <img
                                    loading="lazy"
                                    srcSet="./assets/images/facebook.png"
                                    className="aspect-square object-contain object-center w-8 overflow-hidden shrink-0 max-w-full"
                                    alt=''
                                />
                            </a>
                            <a
                                href='https://www.facebook.com/AkshiBeautyProducts?mibextid=LQQJ4d'
                                target='_blank'
                                rel="noopener noreferrer"
                                onClick={handleClickInstagram}
                            >
                                <img
                                    loading="lazy"
                                    srcSet="./assets/images/instagram.png"
                                    className="aspect-square object-contain object-center w-8 overflow-hidden shrink-0 max-w-full"
                                    alt=''
                                />
                            </a>
                            <a
                                href='https://www.facebook.com/AkshiBeautyProducts?mibextid=LQQJ4d'
                                target='_blank'
                                rel="noopener noreferrer"
                                onClick={handleWhatsAppClick}
                            >
                                <img
                                    loading="lazy"
                                    srcSet="./assets/images/whatsapp.png"
                                    className="aspect-square object-contain object-center w-8 overflow-hidden shrink-0 max-w-full"
                                    alt=''
                                />
                            </a>
                            <a
                                href='https://www.google.com/maps?q=42.74672653504422,-73.75757642358819'
                                target='_blank'
                                rel="noopener noreferrer"
                                onClick={openGoogleMap}
                            >
                                <img
                                    loading="lazy"
                                    srcSet="./assets/images/google-map.png"
                                    className="aspect-square object-contain object-center w-8 overflow-hidden shrink-0 max-w-full"
                                    alt=''
                                />
                            </a>
                        </div>
                    </div> */}

                    <div className="relative text-center text-white text-sm font-semibold leading-6 mt-8">
                        © 2023 Akshi. All rights reserved
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer